/** @format */

.about__container {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 4rem;
}

.about__img {
  width: 350px;
  border-radius: 1.5rem;
  justify-self: center;
}

.about__info {
  grid-template-columns: repeat(3, 140px);
  gap: 0.5rem;
  margin-bottom: 40px;
}

.about__box {
  background-color: var(--container-color);
  border: 1px solid rgba(153, 0, 9, 0.4);
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
}

.about__icon {
  font-size: 1.5rem;
  color: #990009;
  margin-bottom: var(--mb-0-5);
}

.section__title {
  font-size: 60px;
  font-weight: 400;
  font-family: "Goldsmith", sans-serif;
}

.section__subtitle {
  font-size: 16px;
  padding-top: 10px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.about__title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  font-family: Georgia, "Times New Roman", Times, serif;
}

.about__subtitle {
  font-size: var(--smaller-font-size);
}

.about__description {
  margin-bottom: 40px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .about__container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }

  .about__img {
    width: 220px;
  }

  .about__box {
    padding: 0.75rem 0.5rem;
  }

  .about__data {
    text-align: center;
  }

  .about__info {
    justify-content: center;
  }

  .about__description {
    padding: 0 5rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .about__info {
    grid-template-columns: repeat(3, 1fr);
  }

  .about__description {
    padding: 0;
  }
}

@media screen and (max-width: 350px) {
  .about__info {
    grid-template-columns: repeat(2, 1fr);
  }
}
