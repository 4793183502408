/** @format */

.contact__container {
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  column-gap: 6rem;
  padding-bottom: 3rem;
  z-index: -1;
}

.contact__title {
  color: #990009;
  font-family: "Goldsmith", sans-serif;
  text-align: start;
  font-size: 36px;
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.contact__description {
  text-align: justify;
  font-size: 18px;
  width: 250px;
  justify-content: center;
  margin-bottom: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.contact__card-content {
  display: flex;
  flex-direction: row;
}
.contact__info {
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 300px;
  flex-direction: column;
}

.contact__card-title,
.contact__card-data {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.contact__card-title {
  color: #990009;
  font-size: 18px;
  font-weight: var(--font-medium);
}

.contact__card-data {
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
}

.contact__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;
}

.contact__button-icon {
  color: #990009;
  font-size: 1rem;
  transition: 0.3s;
}

.contact__button:hover .contact__button-icon {
  transform: translate(0.25rem);
}

.contact__form {
  width: 360px;
  position: relative; /* Ensure positioning context */
  z-index: 10; /* Must be higher than the particle canvas */
}

.contact__form-div {
  position: relative;
  margin-bottom: var(--mb-2);
  height: 4rem;
}

.contact__form-input {
  font-family: Georgia, "Times New Roman", Times, serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: none;
  color: grey;
  outline: none;
  border-radius: 0.75rem;
  padding: 1.5rem;
  z-index: 1;
  transition: border-color 0.3s ease;
}

.contact__form-input:focus {
  border-color: #990009;
}

.contact__form-tag {
  font-family: Georgia, "Times New Roman", Times, serif;
  position: absolute;
  top: -0.75rem;
  left: 1.25rem;
  font-size: var(--smaller-font-size);
  padding: 0.25rem;
  background-color: var(--body-color);
  z-index: 2;
}

.contact__form-area {
  height: 11rem;
}

.contact__form-area textarea {
  resize: none;
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .contact__container {
    column-gap: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .contact__container {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }

  .contact__info {
    justify-content: center;
  }

  .contact__form {
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .contact__info {
    grid-template-columns: 1fr;
  }

  .contact__form {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
}
