/** @format */

.skills__container {
  grid-template-columns: repeat(2, 540px);
  column-gap: 4rem;
  justify-content: center;
}

.skills__content {
  font-family: Georgia, "Times New Roman", Times, serif;
  background-color: var(--container-color);
  border: 1px solid rgba(153, 0, 9, 0.4);
  padding: 4rem;
  border-radius: 1.25rem;
}

.skills__title {
  font-size: 20px;
  font-weight: 600;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-align: center;
  margin-bottom: 2.5rem;
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 4rem;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 2.5rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
}

.skills svg {
  font-size: 1rem;
  color: #990009;
}

.skills__name {
  font-size: 18px;
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__level {
  font-size: 14px;
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: 25rem;
  }

  .skills__box {
    display: flex;
    justify-content: center;
    column-gap: 2rem;
  }

  .skills__content {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }
}
