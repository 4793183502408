/**
 * GOOGLE FONTS
 *
 * @format
 */

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap");

/* CSS VARIABLES */
:root {
  --header-height: 3rem;
  /* HSL COLORS */
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  /* --title-color-dark: hsl(var(--hue), var(--sat), 0%); */
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;
  /* STATIC TYPOGRAPHY */
  --body-font: "Nunito", sans-serif;
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --smallest-font-size: 0.625rem;
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  /* BOTTOM MARGINS */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;
  /* Z INDEX */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* RESPONSIVE TYPOGRAPHY */
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
    --smallest-font-size: 0.625rem;
  }
}

/* BASE */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semibold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  position: relative; /* Ensure positioning context */
  z-index: 10; /* Must be higher than the particle canvas */
}

img {
  max-width: 100%;
  height: auto;
}

/* CSS CLASSES */

.section {
  padding: 6rem 0 2rem;
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__title {
  text-align: center;
  color: #990009;
}

.section__subtitle {
  text-align: center;
}

/* LAYOUT */
.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/* BUTTONS */
.button {
  display: inline-block;
  background-color: #990009;
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
  font-family: Georgia, "Times New Roman", Times, serif;
  transition: 0.2s ease-in;
  position: relative; /* Ensure positioning context */
  z-index: 10; /* Must be higher than the particle canvas */
}

.button:hover {
  scale: 1.05;
  box-shadow: 0 0 20px #990009;
}

.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

/* TITLE */

.home__title {
  color: #990009;
  font-size: 50px;
  margin-bottom: var(--mb-0-5);
}

.home__title:hover {
  cursor: pointer;
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }

  .button {
    padding: 1rem 1.75rem;
    position: relative; /* Ensure positioning context */
    z-index: 10; /* Must be higher than the particle canvas */
  }

  .button__icon {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 768px) {
  body {
    margin: 00 var(--header-height) 0;
  }
  .section {
    padding: 2rem 0 4rem;
  }

  .section__subtitle {
    margin-bottom: var(--mb-3);
  }
}

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }

  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}

@font-face {
  font-family: "Euphorigenic";
  src: url("./fonts/Euphorigenic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Goldsmith";
  src: url("./fonts/The-Goldsmith_Vintage.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bluunext";
  src: url("./fonts/BluuNext.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Blanka";
  src: url("./fonts/Blanka.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.euphorigenic-text {
  font-family: "Euphorigenic", sans-serif;
}

.goldsmith-text {
  font-family: "Goldsmith", sans-serif;
}

.bluunext-text {
  font-family: "Bluunext", sans-serif;
}

.blanka-text {
  font-family: "Blanka", sans-serif;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Takes the full viewport height */
}
