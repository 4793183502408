/** @format */

.project__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
  position: relative; /* Ensure positioning context */
  z-index: 10; /* Must be higher than the particle canvas */
}

.project__item {
  color: black;
  padding: 0.25rem 0.75rem;
  font-weight: 600;
  border-radius: 0.5rem;
  text-transform: capitalize;
  cursor: pointer;
}

.project__item:hover {
  background-color: #990009;
  color: whitesmoke;
}

.project__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.project__card {
  border: 1px solid rgba(153, 0, 9, 0.4);
  background-color: var(--container-color);
  border-radius: 1.25rem;
  padding: 1.25rem;
  border-radius: 1rem;
}

.project__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.project__title {
  font-size: var(--normal-font-size);
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 600;
  margin-bottom: 4px;
}

.project__subtitle {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: var(--font-medium);
  color: var(--text-color);
  padding-left: 2px;
  margin-bottom: 14px;
}

.project__button {
  color: var(--text-color);
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.project__button-icon {
  color: #990009;
  font-size: 1rem;
  transition: 0.4s;
}

.project__button:hover .project__button-icon {
  transform: translateX(0.25rem);
}

/* ACTIVE PROJECT */
.active__project {
  background-color: var(--title-color);
  color: var(--container-color);
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .project__container {
    gap: 1.25rem;
  }

  .project__card {
    padding: 1rem;
  }

  .project__img {
    margin-bottom: 0.75rem;
  }

  .project__title {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 768px) {
  .project__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .project__container {
    grid-template-columns: 1fr;
  }

  .project__img {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .project__item {
    font-size: var(--small-font-size);
  }

  .project__filters {
    column-gap: 0.25rem;
  }
}
