/** @format */

.home__container {
  row-gap: 7rem;
}

.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1.5rem;
}

.home__social-icon {
  font-size: 1.4rem;
  color: rgb(51, 50, 50);
}

.home__social-icon:hover {
  scale: 1.3;
  color: #990009;
}

.home__title {
  font-size: 60px;
  transition: transform 0.5s ease;
  margin-bottom: var(--mb-0-5);
  font-family: "Bluunext", sans-serif;
  font-size: 50px;
  font-weight: 400;
}

.home__title:hover {
  transform: translate(10px, -10px);
}

/* .home__hand {
  width: 38px;
  height: 38px;
  margin-left: 0.4rem;
} */

.home__subtitle {
  position: relative;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 0.4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
  color: black;
  font-family: Georgia, "Times New Roman", Times, serif;
}

/* .home__subtitle::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--text-color);
  left: 0;
  top: 1rem;
} */

.home__description {
  padding-left: 0.4rem;
  max-width: 400px;
  margin-bottom: var(--mb-3);
  color: black;
  font-family: Georgia, "Times New Roman", Times, serif;
}

/* .home__img {
  background: url(../../assets//profileBW.jpg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  box-shadow: inset 0 0 0 9px grey;
  order: 1;
  justify-self: center;
  width: 350px;
  height: 350px;
  animation: profile__animate 8s ease-in-out infinite 1s;
  margin-left: 50px;
} */

/* @keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
} */

.home__scroll {
  margin-left: 9.25rem;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3.75rem);
  }
}

.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  font-size: 16px;
  margin-left: 2px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.home__scroll-arrow {
  font-size: 1.6rem;
  color: #990009;
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .home__content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home__hand {
    width: 26px;
    height: 26px;
  }
  .home__title {
    font-size: 46px;
    position: relative; /* Ensure positioning context */
    z-index: 10; /* Must be higher than the particle canvas */
  }

  .home__subtitle {
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home__description {
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home__img {
    width: 250px;
    height: 250px;
    box-shadow: inset 0 0 0 9px grey;
  }

  .home__scroll {
    margin-left: 7.5rem;
  }
}
@media screen and (max-width: 768px) {
  .home__content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
  }

  .home__title {
    font-size: 30px;
  }

  .home__social {
    display: flex;
    flex-direction: row;
    column-gap: 1.2rem;
  }

  .home__social-icon {
    font-size: 1.2rem;
    color: rgb(51, 50, 50);
    position: relative; /* Ensure positioning context */
    z-index: 10; /* Must be higher than the particle canvas */
  }

  .home__img {
    order: initial;
    justify-self: initial;
    box-shadow: inset 0 0 0 9px grey;
    width: 200px;
    height: 200px;
  }

  .home__data {
    grid-column: 1/3;
  }

  .home__scroll {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  .home__img {
    width: 180px;
    height: 180px;
  }
}
