/** @format */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loading-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  color: #888; /* Adjust to your preferred color */
}

.ldes {
  font-size: 10px;
  color: grey; /* Adjust color as needed */
}
