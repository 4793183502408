/** @format */

.scrollup {
  align-content: center;
  position: fixed;
  right: 2.5rem;
  bottom: -20%;
  background-color: #990009;
  padding: 0.3rem 0.5rem;
  border-radius: 0.4rem;
  z-index: 10;
  transition: 0.4s;
}

.scrollup__icon {
  font-size: 1.5rem;
  color: white;
}

.scrollup:hover {
  background-color: #5e0107;
}

/* SHOW SCROLL */
.show-scroll {
  bottom: 3rem;
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .scrollup {
    /* right: 1.5rem; */
    padding: 0.5rem 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .show-scroll {
    bottom: 4rem;
  }
}

@media screen and (max-width: 350px) {
  .scrollup {
    right: 1rem;
  }
}
