/** @format */

.loading-icon {
  --uib-size: 45px;
  --uib-color: #990009;
  --uib-speed: 1.25s;
  --uib-bg-opacity: 0.1;
  align-self: center;
  overflow: hidden;
  position: relative;
}

.car {
  stroke: var(--uib-color);
  stroke-dasharray: 100;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: travel var(--uib-speed) ease-in-out infinite, fade var(--uib-speed) ease-out infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
}

.track {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
}

@keyframes travel {
  0% {
    stroke-dashoffset: 100;
  }

  75% {
    stroke-dashoffset: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  20%,
  55% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
